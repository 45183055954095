.roadmapBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.roadmap {
  background-color: black;
  border: #524c42 0.5rem solid;
  border-radius: 15px;
  width: 350px;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .roadmapBox {
    flex-direction: column;
    align-items: center;
    width: 350px;
  }

  .roadmap {
    width: 350px;
  }
}

/* .smoke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1)
  );
  animation: smokey 5s infinite linear;
}

@keyframes smokey {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.smoke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1)
  );
  animation: smokey 5s infinite linear;
}

@keyframes smokey {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
} */
