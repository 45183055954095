.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  background-image: url("../public/IMG_1462.jpg");
  background-repeat: repeat;
  background-size: 150px 150px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
a,
.font-use-1 {
  font-family: "Roboto", sans-serif;
  color: #e2dfd0;
  text-decoration: none;
  font-weight: bold;
}
