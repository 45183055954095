.social {
    display: flex;
    justify-content: space-evenly;
    background-color: #E2DFD0;
    padding: 10px 20px; /* Padding for the navbar content */
    position: fixed;
    bottom: 0; /* Stick the navbar to the bottom of the viewport */
    left: 0;
    width: 150px;
    opacity: .9;
    border-radius: 15px;
    margin: 10px 0;
    transform: translateX(-50%);
    left: 50%
}