.navCont {
  display: flex;
  justify-content: center;
}

.HeaderCont {
  width: 750px;
  height: 150px;
  background-image: url("../imgs/New-header.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 15px;
  border-radius: 25px;
}

.headNavCont {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.navLinks {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 50%;
  text-decoration: none;
  padding: 7.5px;
}

.twIconCont {
  display: flex;
  align-items: center;
}

.tgIconCont {
  display: flex;
  align-items: center;
}
