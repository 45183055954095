.aboutCont {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
  .aboutCont {
    flex-direction: column;
    align-items: center;
  }
}
