.airDropBox {
  display: flex;
  flex-direction: column;
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .airDropBox {
    width: 80%;
  }
}
