.catPanel {
  width: 30%;
}

.catBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  background-color: #e2dfd0;
  opacity: 0.8;
  border: 0.5rem solid #524c42;
  border-radius: 15px;

  h3,
  h4 {
    color: #524c42;
  }

  img {
    margin: 15px;
    width: 25%;
  }
}

@media only screen and (max-width: 768px) {
  .catPanel {
    width: 90%;

    img {
      width: 80px;
    }
  }

  h3,
  h4 {
    color: #524c42;
  }
}
