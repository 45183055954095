.teamCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #e2dfd0;
  margin: 15px;
}

.cardIcon {
  width: 64px;
  height: 64px;
  border-radius: 50px;
}

.teamCard:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.teamCard h2 {
  font-size: 24px;
  color: #524c42;
}

.teamCard p {
  font-size: 16px;
  color: #666;
}

.teamPanel {
  width: 30%;
}

.teamBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  background-color: black;
  border: 0.5rem solid #524c42;
  border-radius: 15px;
}

@media only screen and (max-width: 768px) {
  .teamPanel {
    width: 90%;
  }

  .teamCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    background-color: #e2dfd0;
    margin: 15px;
  }
}
