.twitterBox {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 15px;
}

.twitterPostBox {
  background-color: black;
  height: 847px;
  overflow-y: auto;
  border: 0.5rem solid #524c42;
  border-radius: 15px;
  padding: 15px;
}

@media only screen and (max-width: 768px) {
  .twitterBox {
    flex-direction: column;
    align-items: center;
    width: 75%;
  }
}
